import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`During our first year as research assistants on “The Heart of Darkness” project, we all worked together to discover the reasons why the film was never made. But, the following year, we each  branched off in different directions. My interest in sociology, specifically my interest in historical social inequalities, led me to focus on the labor force employed during the film’s pre-production. I met weekly basis with two other undergraduate research assistants, Alexandra Niforos and Danielle WIlliams, to locate surviving documentation of cast and crew members. At weekly meetings, we discussed and analyzed our findings from the past week, determined which research methods were successful, and which were no longer useful.`}</MDXTag>
<MDXTag name="p" components={components}>{`Along with the other research team leaders (Jordan Stanton, Sydney Simoncini, and Elijah Segraves), I met weekly with Vincent Longo to hone our larger collective research goals, which I then relayed to the undergraduates working on the map project. For example, after we decided to find out about the socioeconomic backgrounds of the cast and crew, I assigned each member of my research team specific individuals to research and directed them to relevant websites. We shared our findings with each other regularly. Thus, I developed both as an independent researcher and as a peer mentor while receiving mentorship from a more experienced researcher. Through this project and the assistance of Peter Knoop and Abbey Roelofs, my team and I became proficient with ArcGIS, which allowed us to create the map and customize its functionalities. Overall, creating the "Interactive Production History Map" was a team effort and a unique learning experience for everyone involved.`}</MDXTag>
<MDXTag name="p" components={components}>{`Erin Ringel, 2020`}</MDXTag></MDXTag>

export const _frontmatter = {"templateKey":"content-pages","title":"Mapping \"The Heart of Darkness\" Team","key":"mapping-the-heart-of-darkness-team","parentKey":"production-history-map","sortOrder":0};

  